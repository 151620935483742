import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=48630142&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=48630142&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48630142",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BTable, BButton, BCard, BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BTable, BButton, BCard, BCol, BRow, BContainer})
    

export default component.exports